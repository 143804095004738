.wrapper {
    background-color: #19233f;
    height: 30.625rem;
    margin-bottom: 7.5rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .background {
        position: absolute;
        width: 8.75rem;
        height: 100%;
        background: #253256;
        transform: skew(-10deg);
        z-index: -1;
        left: 43.75rem;
    }
    .maxWidthWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 71.25rem;
        margin: 0 auto;
        .left {
            .logoContainer {
                display: flex;
                align-items: center;
                img {
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-bottom: 1rem;
                }
            }
            p {
                &:nth-child(2) {
                    font-weight: 600;
                    font-size: 1.25rem;
                    letter-spacing: -0.022em;
                    color: #ffffff;
                    margin-bottom: 2.5rem;
                }
                &:nth-child(3) {
                    font-family: "DM Serif Display";
                    font-weight: 400;
                    font-size: 3rem;
                    letter-spacing: -0.022em;
                    color: #ffffff;
                    // margin-bottom: 2.5rem;
                    span {
                        font-family: "DM Serif Display";
                        font-style: italic;
                        color: #ff713f;
                    }
                }
            }
            .buttonContainer {
                display: flex;
                // justify-content: center;
                button {
                    background-color: transparent;
                    height: 2.8125rem;
                    border: 0.0625rem solid #ffffff;
                    border-radius: 0.5rem;
                    img {
                        height: 100%;
                    }
                    &:first-child {
                        margin-right: 0.625rem;
                    }
                }
            }
        }
        .mockupsContainer {
            display: flex;
            align-items: center;
            .one {
                margin-right: -1rem;
                margin-top: -4rem;
                img {
                    width: 11rem;
                }
            }
            .two {
                z-index: 1;
                margin-bottom: -10rem;
                img {
                    width: 11rem;
                }
            }
            .three {
                margin-left: -1rem;
                img {
                    width: 11rem;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .wrapper {
        margin-bottom: 2.5rem;
        .background {
            left: 21.875rem;
        }
        .maxWidthWrapper {
            .mockupsContainer {
                .one {
                    margin-top: 4rem;
                    img {
                        width: 8rem;
                    }
                }
                .two {
                    margin-bottom: -12rem;
                    img {
                        width: 8rem;
                    }
                }
                .three {
                    margin-top: 6rem;
                    img {
                        width: 8rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 71.25rem) and (min-width: 62.5rem) {
    .wrapper {
        .maxWidthWrapper {
            width: 61.25rem;
        }
    }
}

@media (max-width: 62.5rem) {
    .wrapper {
        height: auto;
        margin-bottom: 3.5rem;
        .background {
            width: 6.25rem;
            left: 15rem;
            transform: skew(-5deg);
        }
        .maxWidthWrapper {
            width: 90%;
            height: 100% !important;
            flex-direction: column;
            height: auto;
            .left {
                padding-top: 5.5rem;
                text-align: center;
                margin-bottom: 8rem;
                .logoContainer {
                    img {
                        width: 2.625rem;
                        height: 2.625rem;
                    }
                }
                p {
                    &:nth-child(2) {
                        text-align: left;
                        font-size: 1rem;
                    }
                    &:nth-child(3) {
                        text-align: left;
                        width: 250px;
                        font-size: 1.5rem;
                    }
                }
            }
            .mockupsContainer {
                .one {
                    img {
                        width: 8rem;
                    }
                }
                .two {
                    margin-bottom: -2rem;
                    img {
                        width: 8rem;
                    }
                }
                .three {
                    img {
                        width: 8rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .wrapper {
        .maxWidthWrapper {
        }
    }
}
