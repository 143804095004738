.container {
    position: relative;
    z-index: 1;
    margin-bottom: 7.5rem;
    .maxWidthWrapper {
        width: 71.25rem;
        margin: 0 auto;
        box-shadow: 0rem 0.25rem 2.5rem rgba(0, 0, 0, 0.1);
        border-radius: 1.25rem;
        overflow: hidden;
        position: relative;
        display: flex;
        .background {
            position: absolute;
            width: 8.75rem;
            height: 100%;
            background: #fff7e8;
            transform: skew(-10deg);
            z-index: -1;
            right: 28.125rem;
        }
        .image {
            margin-left: auto;
        }
        .text {
            width: 100%;
        }
    }
    .left {
        padding: 3.5rem 0 0 3.5rem;
        h1 {
            font-family: "DM Serif Display";
            font-weight: 400;
            font-size: 2.4375rem;
            color: #000000;
            margin-bottom: 0.5rem;
            span {
                color: #ff713f;
                font-family: "DM Serif Display";
                font-style: italic;
            }
        }
        p {
            font-weight: 400;
            font-size: 1rem;
            color: #666666;
        }
    }
    .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 3.5rem 4rem;
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:first-child {
                margin-bottom: 2.5rem;
            }
            .result {
                width: 15.625rem;
                p {
                    &:first-child {
                        font-family: "DM Serif Display";
                        font-style: italic;
                        font-weight: 400;
                        font-size: 2.5rem;
                        letter-spacing: -0.022em;
                        color: #19233f;
                        .animated {
                            font-family: "DM Serif Display";
                            font-style: italic;
                            font-weight: 400;
                            font-size: 2.5rem;
                            letter-spacing: -0.022em;
                            color: #19233f;
                        }
                        span {
                            font-family: "DM Serif Display";
                            font-style: italic;
                            font-weight: 400;
                            font-size: 1.25rem;
                            letter-spacing: -0.022em;
                            color: #19233f;
                        }
                    }
                    &:last-child {
                        font-weight: 400;
                        font-size: 1.25rem;
                        letter-spacing: -0.022em;
                        color: #666666;
                    }
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin-bottom: 2.5rem;
        .maxWidthWrapper {
            // width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthWrapper {
            transform: none;
            width: 90%;
            flex-direction: column;
            padding: 2rem 1.5rem;
            .background {
                right: 6.25rem;
                width: 6.25rem;
                bottom: 0;
            }
            .image {
                display: none;
            }
        }
        .left {
            margin-right: 0;
            width: 100%;
            margin-bottom: 1.5rem;
            padding: 0;
            h1 {
                width: 12.5rem;
                font-weight: 400;
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
            p {
                width: 100%;
                font-size: 0.875rem;
            }
        }
        .right {
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            .row {
                flex-direction: column;
                justify-content: space-between;
                &:first-child {
                    margin-bottom: 0;
                    .result {
                        p {
                            &:last-child {
                                width: 6.875rem;
                            }
                        }
                    }
                }
                &:last-child {
                    .result {
                        p {
                            &:last-child {
                                width: 8.75rem;
                            }
                        }
                    }
                }
                .result {
                    width: auto;
                    margin-bottom: 1.875rem;
                    p {
                        &:first-child {
                            font-size: 1.5rem;
                            .animated {
                                font-size: 1.5rem;
                                margin-bottom: 0.5rem;
                            }
                        }
                        &:last-child {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        .gradient {
            height: 6rem;
            opacity: 0.6;
            right: -12rem;
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .left {
            h1 {
                width: 100%;
            }
            p {
                width: 25rem;
            }
        }
        .right {
            justify-content: flex-start;
            flex-direction: column;
            .row {
                flex-direction: row;
                &:first-child {
                    margin-bottom: 0;
                    .result {
                        p {
                            &:last-child {
                                width: auto;
                            }
                        }
                    }
                }
                &:last-child {
                    .result {
                        p {
                            &:last-child {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
