.container {
    margin-bottom: 6.25rem;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .card {
        width: 22.75rem;
        height: 15.625rem;
        border-radius: 0.75rem;
        background-color: #ffffff;
        box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(101, 101, 101, 0.17);
        padding: 2rem;
        .rating {
            margin-bottom: 2rem;
            font-size: 1.125rem;
        }
        p {
            &:nth-child(2) {
                color: #000;
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            &:nth-child(3) {
                color: #666;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5rem;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            width: 100%;
            flex-direction: column;
        }
        .card {
            width: 90%;
            height: auto;
            margin-bottom: 1rem;
            padding: 1.25rem;
            .rating {
                margin-bottom: 1rem;
            }
            p {
                &:nth-child(2) {
                    font-size: 1rem;
                }
                &:nth-child(3) {
                    font-size: 0.875rem;
                }
            }
        }
    }
}
