.container {
    margin: 7.5rem 0;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        background: url("https://d1shve8gekzify.cloudfront.net/website-assets/hands1-min.png"),
            #19233fe5;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        border-radius: 1.25rem;
        overflow: hidden;
        padding: 2.625rem 4rem;
    }
    h2 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.1875rem;
        color: #ffffff;
        margin-bottom: 2rem;
        span {
            font-family: "DM Serif Display";
            color: #ff713f;
            font-style: italic;
        }
    }
    .founders {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        gap: 2.5rem;
        .founder {
            width: calc(100% / 3);
            img {
                width: 11.875rem;
                margin-bottom: 2rem;
            }
            div {
                display: flex;
                align-items: center;
                p {
                    font-size: 1.25rem;
                    font-weight: 500;
                    line-height: 2.25rem;
                    margin-right: 0.625rem;
                }
                svg {
                    cursor: pointer;
                    font-size: 1.5rem;
                    color: #ff713f;
                }
            }
            .title {
                color: #bfbfbf;
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 0.75rem;
            }
            .description {
                color: #bfbfbf;
                font-size: .875rem;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        margin-bottom: 2.5rem;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin: 0;
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            transform: none;
            width: 90%;
            padding: 2rem 1.25rem;
        }
        h2 {
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
        .founders {
            flex-direction: column;
            gap: 0;
            .founder {
                width: 100%;
                margin-bottom: 2.5rem;
                img {
                    width: 8rem;
                    margin-bottom: 1rem;
                }
                div {
                    p {
                        font-size: 1rem;
                    }
                    svg {
                        font-size: 1.25rem;
                    }
                }
                .title {
                    font-size: .875rem;
                    margin-bottom: 0.5rem;
                }
                
            }
        }
    }
}

// @media (min-width: 48rem) and (max-width: 62.5rem) {
// }
