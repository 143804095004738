.container {
    margin-top: 40px;
    width: 500px;
    border-radius: 14px;
    background-color: #ffffff;
    margin-bottom: auto;
    padding: 35px;
    position: relative;
    text-align: center;
    .tick {
        margin-bottom: 32px;
        svg {
            font-size: 100px;
            color: #23a26d;
            margin-bottom: 18px;
        }
        p {
            color: #23a26d;
            font-family: "Inter";
            font-size: 20px;
            font-weight: 600;
        }
    }
    .amount {
        color: #111;
        font-family: "Inter";
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 24px;
        span {
            font-weight: 700;
        }
    }
    .issuer {
        color: #111;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
    }
    .serviceDate {
        color: #7c7c7c;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 40px;
    }
    .transactionId,
    .paymentDate,
    .paymentMethod {
        color: #7c7c7c;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }
}
