.container {
    margin: 7.5rem 0;
    padding-bottom: 0;
    .maxWidthWrapper {
        width: 71.25rem;
        margin: 0 auto;
    }
    .heading {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.8125rem;
        color: #000000;
        margin-bottom: 0.5rem;
        span {
            color: #ff713f;
            font-family: "DM Serif Display";
            font-style: italic;
        }
    }
    .info {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;
        color: #19233f;
        margin-bottom: 1.75rem;
    }
    .pricingTabs {
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 0.75rem;
        border: 1px solid #ff713f;
        border-radius: 0.5rem;
        padding: 4px;
        background-color: #ffffff;
        button {
            background-color: transparent;
            padding: 0.75rem 2.25rem;
            border-radius: 0.5rem;
            color: #ff713f;
            font-size: 1rem;
            font-weight: 600;
        }
        .active {
            background-color: #ffe4db;
            color: #ff713f;
            span {
                background-color: #ffffff;
                color: #ff713f;
            }
        }
    }
    .discountInfo {
        margin-bottom: 2.5rem;
        text-align: center;
        color: #19233f;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        span {
            font-weight: 700;
            color: #ff713f;
        }
    }
    .pricingCards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        position: relative;
        .card {
            width: 23.625rem;
            background: #ffffff;
            box-shadow: 0 0.25rem 1.25rem rgba(101, 101, 101, 0.17);
            border-radius: 0.75rem;
            padding: 2.5rem 1.5rem;
            &:first-child {
                min-height: 43rem;
            }
            &:nth-child(3) {
                min-height: 43rem;
            }
            .client {
                font-weight: 700;
                font-size: 0.875rem;
                color: #a1a9b4;
                letter-spacing: 0.08em;
                margin-bottom: 1.25rem;
                word-spacing: 0.0625rem;
            }
            .name {
                font-family: "DM Serif Display";
                font-style: italic;
                font-weight: 400;
                font-size: 1.625rem;
                color: #ff713f;
                margin-bottom: 0.75rem;
            }
            .desc {
                font-weight: 400;
                font-size: 1rem;
                color: #64748b;
                margin-bottom: 1.25rem;
            }
            .price {
                font-weight: 300;
                font-size: 1rem;
                color: #4b5768;
                margin-bottom: 1.5rem;
                span {
                    font-weight: 600;
                    font-size: 1.625rem;
                    color: #191d23;
                }
            }
            .billing {
                color: #a1a9b4;
                font-size: 0.875rem;
                font-weight: 500;
                margin-bottom: 1.5rem;
            }
            button {
                width: 100%;
                border-radius: 0.5rem;
                border: 0.0625rem solid #000000;
                padding: 0.75rem 0;
                background-color: #ffffff;
                font-weight: 600;
                font-size: 1rem;
                color: #000000;
                transition: all 0.2s;
                margin-bottom: 2.5rem;
            }
            a {
                display: block;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 0.5rem;
                border: 0.0625rem solid #000000;
                padding: 0.75rem 0;
                background-color: #ffffff;
                font-weight: 600;
                font-size: 1rem;
                color: #000000;
                transition: all 0.2s;
                margin-bottom: 2.5rem;
                svg {
                    font-size: 1.25rem;
                    margin-left: 0.5rem;
                }
            }
            .highlight_cta {
                background-color: #ff713f;
                border-color: #ff713f;
                color: #ffffff;
            }
            .mobileToggle {
                display: none;
                background-color: transparent;
                border: none;
                padding: 0;
                width: fit-content;
                align-items: center;
                font-weight: 400;
                font-size: 1rem;
                color: #64748b;
                svg {
                    font-size: 1.5rem;
                    color: #64748b;
                    transition: all 0.2s;
                }
                &:hover {
                    background-color: transparent;
                    color: #64748b;
                }
            }
            .activeButton {
                svg {
                    transform: rotate(90deg);
                }
            }
            .feature {
                margin-bottom: 1.5rem;
                display: flex;
                align-items: center;
                span {
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1rem;
                    color: #ff713f;
                }
                p {
                    font-weight: 500;
                    font-size: 0.875rem;
                    color: #191d23;
                }
                .highlight {
                    // color: #1d4ed8;
                    font-weight: 700;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 0;
        margin: 0;
        margin-top: -2.5rem;
        .maxWidthWrapper {
            // width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-top: 0;
        margin-bottom: 5rem;
        .maxWidthWrapper {
            transform: none;
            width: 90%;
        }
        .heading {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        .info {
            font-size: 0.875rem;
            margin-bottom: 2rem;
        }

        .pricingCards {
            flex-direction: column;
            .card {
                width: 100%;
                &:first-child {
                    min-height: auto;
                }
                &:nth-child(3) {
                    min-height: auto;
                }
                .client {
                    font-size: 0.875rem;
                }
                .mobileToggle {
                    display: flex;
                    margin: 0;
                }
                .featureContainer {
                    max-height: 0;
                    opacity: 0;
                    overflow: hidden;
                    transition: all 0.2s;
                }
                .activeCard {
                    margin-top: 1.5rem;
                    max-height: 31.25rem;
                    opacity: 1;
                }
            }
            .gradient {
                width: 25rem;
                height: 25rem;
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .pricingCards {
            align-items: flex-start;
            .card {
                width: 25rem;
            }
        }
    }
}
