.wrapper {
    padding: 4.375rem 1.875rem 4.375rem 1.875rem;
    background-image: url("https://d1shve8gekzify.cloudfront.net/website-assets/hero2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    position: relative;
    z-index: 1;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #19233fe5;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .maxWidthWrapper {
        width: 71.25rem;
        margin: 0 auto;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
    }
    .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-child {
            margin-bottom: 3.5rem;
        }
        .logo {
            display: flex;
            align-items: center;
            img {
                width: 2.5rem;
                margin-right: 1rem;
            }
            div {
                p {
                    &:first-child {
                        font-weight: 700;
                        font-size: 1.25rem;
                        letter-spacing: -0.022em;
                        color: #ffffff;
                        margin-bottom: 0.25rem;
                    }
                    &:last-child {
                        font-weight: 500;
                        font-size: 1rem;
                        letter-spacing: -0.022em;
                        color: #ffffff;
                    }
                }
            }
        }
        .socials {
            display: flex;
            img {
                margin-right: 1.875rem;
            }
            button {
                background-color: transparent;
                color: #ffffff;
                font-size: 2.25rem;
                &:nth-child(2){
                    margin-right: 1.875rem;
                }
            }
        }
        .buttons {
            button {
                background-color: transparent;
                width: 8rem;
                &:first-child {
                    margin-right: 0.5rem;
                }
                img {
                    width: 100%;
                }
            }
        }
        .text {
            text-align: right;
            p {
                font-weight: 500;
                font-size: 1rem;
                letter-spacing: -0.022em;
                color: #ffffff;
                &:first-child {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .wrapper {
        padding: 2.5rem 0;
        .maxWidthWrapper {
            // width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .wrapper {
        padding: 3.125rem 0;
        .maxWidthWrapper {
            transform: none;
            width: 90%;
        }
        .row {
            align-items: flex-start;
            &:last-child {
                flex-direction: column;
            }
            .logo {
                flex-direction: column;
                align-items: flex-start;
                img {
                    width: 1.875rem;
                    margin: 0;
                    margin-bottom: 1.5rem;
                }
                div {
                    &:first-child {
                        font-size: 1.125rem;
                    }
                    &:last-child {
                        font-weight: 400;
                    }
                }
            }
            .socials {
                img {
                    width: 3.75rem;
                }
                button {
                    font-size: 1.75rem;
                }
            }
            .buttons {
                display: flex;
                justify-content: space-between;
                margin-bottom: 3rem;
                button {
                    width: 45%;
                    &:first-child {
                        margin-right: 0;
                    }
                }
            }
            .text {
                text-align: left;
                p {
                    font-size: 0.875rem;
                }
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .wrapper {
        .row {
            &:first-child {
                margin-bottom: 1rem;
            }
            .buttons {
                margin-bottom: 1rem;
                button {
                    width: 10rem;
                    &:first-child {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
