.container {
  border-top: 0.3125rem solid #ffffff;
  border-right: 0.3125rem solid #ffffff;
  border-bottom: 0.3125rem solid #ffffff;
  border-left: 0.3125rem solid #000000;
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
}

.invert {
  border-top-color: #000000;
  border-right-color: #000000;
  border-bottom-color: #000000;
  border-left-color: #ffffff;
}

.buttonLoader {
  width: 1.2rem;
  height: 1.2rem;
  border-left-color: transparent;
  border-width: 0.25rem;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
