.container {
    padding: 7.5rem 0;
    background-color: #fffbf3;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        color: #19233f;
        font-family: "DM Serif Display";
        font-size: 2.8125rem;
        font-weight: 400;
        margin-bottom: 2.625rem;
        span {
            color: #ff713f;
            font-family: "DM Serif Display";
            font-style: italic;
        }
    }
    .about {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30rem;
        img {
            height: 100%;
            margin-right: 2.8125rem;
        }
        .aboutText {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                color: #6f6f6f;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.75rem;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 2.5rem 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 3.5rem 0;
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        h1 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .about {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            img {
                width: 50%;
                margin-right: 0;
                margin-bottom: 1.5rem;
            }
            .aboutText {
                p {
                    font-size: .875rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}