.container {
    margin-bottom: 7.5rem;
    .maxWidthContainer {
        max-width: 71.25rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .image {
        margin-right: 3.125rem;
    }
    .text {
        // width: 100%;
        display: flex;
        flex-direction: column;
        h1 {
            font-family: "DM Serif Display";
            font-weight: 400;
            font-size: 2.5rem;
            color: #000000;
            margin-bottom: 1.5rem;
        }
        p {
            width: 100%;
            font-weight: 400;
            font-size: 1rem;
            color: #19233f;
            line-height: 1.75rem;
            &:nth-child(3) {
                margin-top: auto;
                // margin-bottom: -3rem;
            }
        }
    }
    .logos {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: auto;
        gap: 2rem;
        margin-bottom: 6px;
        img {
            &:last-child {
                width: 12.5rem;
            }
        }
    }
}

@media (min-width: 71.25rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        .maxWidthContainer {
            width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            flex-direction: column;
            width: 90%;
        }
        .image {
            width: 100%;
            margin-bottom: 2rem;
            img {
                width: 100%;
            }
        }
        .text {
            h1 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
            p {
                font-size: 0.875rem;
                line-height: 1.5rem;
                margin-bottom: 1rem;
                &:nth-child(3) {
                    margin-bottom: 1rem;
                }
            }
        }
        .logos {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .image {
            width: 25rem;
        }
        .logos {
            align-items: flex-start;
        }
    }
}
