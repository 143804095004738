.container {
    margin: 7.5rem 0;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: url("https://d1shve8gekzify.cloudfront.net/website-assets/hands1-min.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        border-radius: 1.25rem;
        overflow: hidden;
        position: relative;
        z-index: 1;
        padding: 6.25rem 5rem;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #19233fe5;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .text {
        color: #ffffff;
        position: relative;
        z-index: 0;
        margin-right: 1.5rem;
        h2 {
            font-family: "DM Serif Display";
            font-weight: 400;
            font-size: 2.1875rem;
            color: #ffffff;
            margin-bottom: 0.5rem;
            span {
                font-family: "DM Serif Display";
                color: #ff713f;
                font-style: italic;
            }
        }
        p {
            width: 95%;
            font-size: 1.25rem;
            line-height: 2.25rem;
            font-weight: 400;
            margin-bottom: 3rem;
        }
        button {
            width: 12.8125rem;
            height: 3rem;
            border-radius: 0.5rem;
            background-color: #ffffff;
            color: #000000;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3.125rem;
            svg {
                transition: all 0.2s;
                margin-left: 0.5rem;
            }
            &:hover {
                svg {
                    transform: rotate(-30deg);
                }
            }
        }
    }
    .form {
        form {
            width: 100%;
            input {
                width: 100%;
                height: 3.25rem;
                padding-left: 1rem;
                border: none;
                border-radius: 0.5rem;
                margin-bottom: 1rem;
                font-size: 1.125rem;
                font-weight: 500;
                &::placeholder {
                    color: #aab7ca;
                    font-size: 1.125rem;
                    font-weight: 500;
                }
            }
            textarea {
                width: 100%;
                height: 6.25rem;
                border: none;
                border-radius: 0.5rem;
                padding: 0.5rem 1rem;
                font-size: 1.125rem;
                font-weight: 500;
                margin-bottom: 2rem;
                &::placeholder {
                    color: #aab7ca;
                    font-size: 1.125rem;
                    font-weight: 500;
                }
            }
            button {
                width: 100%;
                border-radius: 0.5rem;
                background: #ff713f;
                padding: 0.875rem 0;

                color: #fff;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        margin-bottom: 2.5rem;
        .maxWidthContainer {
            // width: 100%;
            transform: scale(0.8);
        }
        img {
            margin-bottom: -6rem;
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin: 0;
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            padding: 4rem 2rem;
            transform: none;
            width: 90%;
            flex-direction: column;
            align-items: center;
        }
        .text {
            padding: 0;
            text-align: center;
            padding-top: 2rem;
            margin-right: 0;
            h2 {
                width: 18.25rem;
                margin: 0 auto;
                font-size: 1.8125rem;
                line-height: 2.4375rem;
                letter-spacing: -0.02em;
                margin-bottom: 1.5rem;
            }
            p {
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: -0.022em;
                margin-bottom: 1.5rem;
            }
            button {
                margin: 0 auto;
                width: 9.375rem;
                height: 2.625rem;
                font-size: 0.875rem;
                margin-bottom: 2.5rem;
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .form {
            width: 100%;
            form {
                width: 100%;
            }
        }
    }
}
