.container {
    position: relative;
    z-index: 1;
    padding-top: 150px;
    .yellow {
        background-color: #19233f;
        height: 30rem;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    .header {
        text-align: center;
        h1 {
            font-family: 'DM Serif Display';
            width: 945px;
            margin: 0 auto;
            font-weight: 400;
            font-size: 2.5rem;
            color: #FFFFFF;
            margin-bottom: 4.75rem;
        }
        img {
            width: 100%;
            margin-bottom: 5rem;
        }
    }
    .content {
        width: 49.625rem;
        margin: 0 auto;
        .paragraph {
            margin-bottom: 3.5rem;
            h6,
            p {
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 2rem;
                color: #424242;
            }
            h6 {
                font-weight: 700;
            }
        }
        .quote {
            margin-bottom: 3.5rem;
            p {
                &:first-child {
                    font-weight: 600;
                    font-size: 1.75rem;
                    line-height: 2.8125rem;
                    color: #000000;
                    margin-bottom: 1.5rem;
                }
                &:last-child {
                    font-weight: 500;
                    font-size: 1.125rem;
                    line-height: 2rem;
                    color: #000000;
                }
            }
        }
        .image {
            margin-bottom: 3.5rem;
            img {
                width: 100%;
            }
        }
        .ol {
            margin-bottom: 3.5rem;
            p {
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 2rem;
                color: #424242;
            }
            ol {
                margin-left: 1.5rem;
                li {
                    font-weight: 500;
                    font-size: 1.125rem;
                    line-height: 2rem;
                    color: #424242;
                }
            }
        }
    }
    .relatedBlogs {
        padding: 7.5rem 0;
        h1 {
            font-family: "DM Serif Display";
            font-weight: 400;
            font-size: 2.8125rem;
            color: #000000;
            margin-bottom: 4rem;
        }
        .cards {
            display: flex;
            gap: 2.625rem;
            div {
                width: 22.1875rem;
                display: flex;
                flex-direction: column;
                border-bottom: 0.0625rem solid #d9d9d9;
                img {
                    width: 100%;
                    margin-bottom: 0.75rem;
                }
                p {
                    font-weight: 400;
                    font-size: 1.375rem;
                    letter-spacing: -0.022em;
                    color: #000000;
                    margin-bottom: 2.5rem;
                }
                button {
                    width: fit-content;
                    margin-top: auto;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 1rem;
                    letter-spacing: -0.022em;
                    color: #FF713F;
                    background-color: transparent;
                    margin-bottom: 1rem;
                    svg {
                        font-size: 1.25rem;
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 75rem) {
    .container {
        .maxWidthContainer {
            width: 62.5rem;
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        .yellow {
            height: 20rem;
        }
        .maxWidthContainer {
            width: 90%;
        }
        .header {
            h1 {
                width: auto;
                font-weight: 600;
                font-size: 1.25rem;
                text-align: center;
                margin-bottom: 2.25rem;
            }
            img {
                margin-bottom: 2rem;
            }
        }
        .content {
            width: 100%;
            .paragraph {
                margin-bottom: 2rem;
                h6,
                p {
                    font-size: 0.875rem;
                    line-height: 1.625rem;
                }
            }
            .quote {
                margin-bottom: 2rem;
                p {
                    &:first-child {
                        font-size: 1.25rem;
                        line-height: 2.125rem;
                        margin-bottom: 1rem;
                    }
                    &:last-child {
                        font-size: 0.875rem;
                    }
                }
            }
            .image {
                margin-bottom: 2rem;
                img {
                    width: 100%;
                }
            }
            .ol {
                margin-bottom: 2rem;
                p {
                    font-size: 0.875rem;
                    line-height: 1.625rem;
                }
                ol {
                    li {
                        font-size: 0.875rem;
                        line-height: 1.625rem;
                    }
                }
            }
        }
        .relatedBlogs {
            padding: 2.5rem 0;
            h1 {
                font-size: 1.5rem;
                margin-bottom: 2.5rem;
            }
            .cards {
                flex-direction: column;
                div {
                    width: 100%;
                    img {
                        margin-bottom: 1.5rem;
                    }
                    p {
                        font-size: 1.25rem;
                        margin-bottom: 1rem;
                    }
                    button {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
