.container {
    padding: 3.75rem 0;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        img {
            margin-bottom: 0.875rem;
        }
        p {
            color: #6e6e6e;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: -0.022rem;
        }
    }
    .text {
        text-align: right;
        .socials {
            margin-bottom: 1rem;
            button {
                font-size: 1.5rem;
                background-color: transparent;
                color: #ff713f;
                &:first-child {
                    margin-right: 1.875rem;
                }
            }
        }
        p {
            color: #6e6e6e;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: -0.022rem;
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 3.5rem 0;
        .maxWidthContainer {
            width: 90%;
            transform: none;
            flex-direction: column;
        }
        .logo {
            width: 100%;
            margin-bottom: 1.5rem;
            img {
                width: 7rem;
            }
            p {
                font-size: 0.875rem;
            }
        }
        .text {
            text-align: left;
            width: 100%;
            .socials {
                button {
                    &:first-child {
                        margin-right: 1rem;
                    }
                }
            }
            p {
                font-size: 0.875rem;
            }
        }
    }
}
