.container {
    background-color: #fffbf3;
    padding-bottom: 7.5rem;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        background-color: #fff7e1;
        border-radius: 1.25rem;
        padding: 3.5rem 4.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left {
        h1 {
            color: #19233f;
            font-family: "DM Serif Display";
            font-size: 2.8125rem;
            font-weight: 400;
            margin-bottom: 2.5rem;
            span {
                color: #ff713f;
                font-family: "DM Serif Display";
                font-style: italic;
            }
        }
        p {
            width: 31.25rem;
            color: #6e6e6e;
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 2.4375rem;
        }
    }
    .right {
        form {
            width: 25rem;
            display: flex;
            flex-direction: column;
            input {
                padding: 0 1rem;
                height: 3.25rem;
                border: none;
                border-radius: 0.5rem;
                margin-bottom: 1rem;
            }
            textarea {
                padding: 0.5rem 1rem;
                height: 6.25rem;
                border: none;
                border-radius: 0.5rem;
                margin-bottom: 1.5rem;
                resize: vertical;
            }
            button {
                padding: 0.75rem 1rem;
                border-radius: 6.25rem;
                background: #ff713f;
                color: #ffffff;
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: -0.011rem;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding-bottom: 2.5rem;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding-bottom: 3.5rem;
        .maxWidthContainer {
            width: 90%;
            transform: none;
            flex-direction: column;
            padding: 2rem 1.5rem;
        }
        .left {
            width: 100%;
            h1 {
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
            }
            p {
                width: 100%;
                font-size: 0.875rem;
                line-height: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }
        .right {
            width: 100%;
            form {
                width: 100%;
            }
        }
    }
}
