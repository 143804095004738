.container {
  display: flex;
  flex-direction: column;
  label {
    color: #16243f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  input {
    padding: 10px 16px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    &::placeholder {
      color: #a0a0a0;
    }
    &:focus {
      border-color: #4384e6;
    }
  }
}

.error {
  label {
    color: #e8505b !important;
  }
  input {
    border-color: #e8505b !important;
  }
}
