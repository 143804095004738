.container {
    display: flex;
    align-items: center;
    height: 80vh;
    position: relative;
    .left {
        width: 60%;
        height: 100% !important;
        height: auto;
        background: url("../../../assets/raklanding/herobg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text {
            width: 71.25rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            h1 {
                color: #000000;
                font-family: "DM Serif Display";
                font-size: 3.375rem;
                font-style: italic;
                font-weight: 400;
                letter-spacing: -0.0663rem;
                line-height: 3.875rem;
                margin-bottom: 1.875rem;
                span {
                    color: #ff713f;
                    font-family: "DM Serif Display";
                    font-size: 2.5rem;
                    font-style: italic;
                    font-weight: 400;
                    letter-spacing: -0.05rem;
                }
            }
            p {
                width: 29.375rem;
                color: #6f6f6f;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.75rem;
                margin-bottom: 3rem;
            }
            a {
                text-decoration: none;
            }
            button {
                padding: 0.75rem 1rem;
                border-radius: 6.25rem;
                background: #ff713f;
                color: #ffffff;
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: -0.011rem;
                display: flex;
                align-items: center;
                text-decoration: none;
                svg {
                    font-size: 1.125rem;
                    margin-left: 0.75rem;
                }
            }
        }
    }
    .right {
        width: 40%;
        height: 80vh;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        .left {
            .text {
                transform: translate(-50%, -50%) scale(0.8);
            }
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        flex-direction: column-reverse;
        height: auto;
        .left {
            width: 100%;
            padding: 2.5rem 0;
            .text {
                width: 90%;
                position: static;
                transform: none;
                h1 {
                    font-size: 1.875rem;
                    line-height: 2rem;
                    margin-bottom: 1rem;
                    span {
                        font-size: 1.375rem;
                    }
                }
                p {
                    width: 100%;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    margin-bottom: 2rem;
                }
            }
        }
        .right {
            width: 100%;
            height: auto;
        }
    }
}
