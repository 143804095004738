.button {
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    z-index: 10;
    button {
        height: 3rem;
        padding: 0 1rem;
        background-color: #000000;
        border-radius: .5rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-size: 1rem;
        font-weight: 400;
        svg {
            margin-right: .5rem;
        }
    }
}

.modalContainer {
    width: 100%;
    height: 100vh;
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(34, 34, 34, 0.26);
    backdrop-filter: blur(.3125rem);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .modal {
        background-color: #ffffff;
        padding: 2.1875rem;
        border-radius: .875rem;
        width: 31.25rem;
        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.875rem;
            p {
                color: #000;
                font-family: "Inter";
                font-size: 1.5rem;
                font-weight: 500;
            }
            button {
                font-size: 1.5rem;
                background-color: transparent;
            }
        }
        form {
            display: flex;
            flex-direction: column;
            input {
                padding: .75rem 1rem;
                background-color: #f3f3f3;
                border: none;
                border-radius: .5rem;
                margin-bottom: 1rem;
            }
            textarea {
                padding: .75rem 1rem;
                height: 6.25rem;
                background-color: #f3f3f3;
                border: none;
                border-radius: .5rem;
                resize: none;
                margin-bottom: 2rem;
            }
            button {
                background-color: #111111;
                border-radius: .5rem;
                height: 3.25rem;
                color: #fff;
                font-family: "Inter";
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
}



@media (max-width: 56.25rem) {
    .button {
        position: static;
        margin: 2rem 0;
    }
    .modalContainer {
        .modal {
            width: 90%;
        }
    }
}