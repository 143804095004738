.container {
    padding: 4rem 0;
    background: linear-gradient(79deg, #fff6e0 80%, rgba(255, 242, 208, 0) 100%);
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left {
        h1 {
            color: #19233f;
            font-family: "DM Serif Display";
            font-size: 2.8125rem;
            font-weight: 400;
            margin-bottom: 3.5rem;
            span {
                color: #ff713f;
                font-family: "DM Serif Display";
                font-style: italic;
            }
        }
        .features {
            display: flex;
            .column {
                &:first-child {
                    margin-right: 2.625rem;
                }
                div {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1rem;
                    p {
                        color: #6f6f6f;
                        font-size: 1.125rem;
                        font-weight: 400;
                    }
                    svg {
                        color: #ff713f;
                        margin-right: .375rem;
                    }
                }
            }
        }
    }
    .right {
        .card {
            width: 24.625rem;
            height: 29.25rem;
            border-radius: .75rem;
            background: #fff;
            box-shadow: .25rem .6875rem 2.9375rem 0rem rgba(206, 126, 52, 0.2);
            img {
                width: 100%;
            }
            .text {
                padding: 2.5rem 1.5rem 1.5rem 1.5rem;
                p {
                    color: #000;
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin-bottom: 1.5rem;
                }
                button {
                    padding: .75rem 3.75rem;
                    background-color: #ffffff;
                    border-radius: 2.3125rem;
                    border: .0625rem solid #ff713f;

                    color: #ff713f;
                    font-size: 1rem;
                    font-weight: 500;
                    letter-spacing: -0.011rem;
                }
            }
        }
    }
}


@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 2rem 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 2rem 0;
        .maxWidthContainer {
            transform: none;
            width: 90%;
            flex-direction: column;
        }
        .left {
            width: 100%;
            h1 {
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
            }
            .features {
                flex-direction: column;
                margin-bottom: 2rem;
                .column {
                    &:first-child {
                        margin-right: 0;
                    }
                    div {
                        p {
                            font-size: .875rem;
                        }
                    }
                }
            }
        }
        .right {
            .card {
                width: 100%;
                height: auto;
                .text {
                    padding: 1.2rem;
                    p {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}