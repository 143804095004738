.container {
    background: #f9f9f9;
    padding-top: 24px;
    min-height: 100vh;
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        img {
            width: 52px;
            margin-right: 12px;
        }
        p {
            color: #111;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.44px;
        }
    }

    .form {
        width: 600px;
        background-color: #ffffff;
        margin: 0 auto;
        padding: 24px;
        border-radius: 10px;
    }
}

.input,
.textarea,
.dropdown {
    margin-bottom: 24px;
    p {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
        span {
            color: #e8505b;
        }
    }
}

.radio {
    margin-bottom: 24px;
}

.radioOption {
    margin-bottom: 8px;
}

.title {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    span {
        color: #e8505b;
    }
}

.submit {
    margin: 32px 0;
    width: 100%;
    padding: 16px 0;
    background-color: #4384e6;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}

.formFilled {
    width: 600px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 10px;
    svg {
        font-size: 72px;
        margin-bottom: 18px;
    }
    p {
        color: #23a26d;
        font-size: 20px;
        font-weight: 600;
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 800px) {
    .container {
        .form {
            width: 90%;
        }
    }

    .formFilled {
        width: 90%;
    }
}
