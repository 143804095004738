.container {
    padding: 2rem 0;
    background-color: #ffffff;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    button {
        padding: 0.75rem 1rem;
        border-radius: 6.25rem;
        background: #ff713f;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.011rem;
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 1rem 0;
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        img {
            width: 7rem;
        }
    }
}