.container {
    margin-bottom: 6.25rem;
    background-image: url("https://d1shve8gekzify.cloudfront.net/website-assets/features.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    // background-attachment: fixed;
    padding: 6.25rem 0 5rem 0;
    position: relative;
    z-index: 1;

    .background {
        position: absolute;
        width: 11.25rem;
        height: 100%;
        background: #253256;
        transform: skew(-10deg);
        z-index: -1;
        top: 0;
        right: 25rem;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #19233fe5;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .background {
        height: 100%;
    }
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.8125rem;
        color: #ffffff;
        margin-bottom: 5rem;
        z-index: 100;
        span {
            color: #ff713f;
            font-family: "DM Serif Display";
            font-style: italic;
        }
    }
    .featuresContainer {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        .feature {
            width: 19.375rem;
            margin-bottom: 5rem;
            .image {
                display: flex;
                align-items: center;
                height: 5.625rem;
                border-radius: 0.5rem;
                margin-bottom: 1.5rem;
            }
            p {
                color: #ffffff;
                &:nth-child(2) {
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 2.25rem;
                    letter-spacing: -0.022em;
                    margin-bottom: 0.25rem;
                }
                &:last-child {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.75rem;
                    color: #bfbfbf;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 0;
        margin-bottom: 2.5rem;
        .maxWidthContainer {
            // width: 100%;
            transform: scale(0.8);
        }
        .background {
            right: 8.75rem;
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        background-position: center;
        padding: 3.5rem 0;
        .background {
            display: none;
        }
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        h1 {
            font-size: 1.5rem;
            line-height: normal;
            margin-bottom: 3rem;
        }
        .featuresContainer {
            flex-direction: column;
            &:nth-child(2){
                margin-bottom: 3.25rem;
            }
            .feature {
                width: 100%;
                margin-bottom: 3.25rem;
                &:last-child {
                    margin-bottom: 0;
                }
                .image {
                    height: auto;
                }
                p {
                    &:nth-child(2) {
                        font-size: 1.125rem;
                        line-height: 2.25rem;
                        letter-spacing: -0.022em;
                        margin-bottom: 0.5rem;
                    }
                    &:last-child {
                        font-size: 0.875rem;
                        line-height: 1.5rem;
                        letter-spacing: -0.022em;
                    }
                }
            }
            .moreText {
                font-size: 1.875rem;
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .featuresContainer {
            width: 25rem;
        }
    }
}
