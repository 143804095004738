.container {
    margin-bottom: 7.5rem;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    .partner {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4.5rem;
        .left {
            p {
                &:first-child {
                    font-size: 1.375rem;
                    font-weight: 600;
                }
                &:nth-child(2) {
                    font-family: "DM Serif Display";
                    font-style: italic;
                    font-weight: 400;
                    font-size: 2.5rem;
                    color: #19233f;
                    margin-bottom: 1.5rem;
                }
                &:nth-child(3) {
                    font-size: 1.25rem;
                    font-weight: 400;
                    color: #666666;
                    line-height: 2.125rem;
                    width: 35rem;
                    margin-bottom: 2.5rem;
                }
            }
            button {
                width: 12.8125rem;
                height: 3rem;
                border-radius: 0.5rem;
                font-size: 1.125rem;
                font-weight: 500;
                transition: all 0.2s;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: 1px solid #ff713f;
                color: #ff713f;
                svg {
                    transition: all 0.2s;
                    margin-left: 0.5rem;
                }
            }
            a {
                text-decoration: none;
                width: 12.8125rem;
                height: 3rem;
                border-radius: 0.5rem;
                font-size: 1.125rem;
                font-weight: 500;
                transition: all 0.2s;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: 1px solid #ff713f;
                color: #ff713f;
                svg {
                    transition: all 0.2s;
                    margin-left: 0.5rem;
                }
            }
            .black {
                border-color: #000000;
                color: #000000;
                &:hover {
                    color: #ffffff !important;
                    background-color: #000000 !important;
                    svg {
                        transform: rotate(90deg);
                    }
                }
                &:active {
                    background-color: #000000 !important;
                }
            }
        }
        .right {
            width: 25.375rem;
            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        margin-top: -2.5rem;
        .maxWidthContainer {
            // width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        .partner {
            flex-direction: column-reverse;
            margin-bottom: 3rem;
            .left {
                p {
                    &:first-child {
                        font-size: 1.125rem;
                    }
                    &:nth-child(2) {
                        font-size: 1.5rem;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(3) {
                        width: 100%;
                        font-size: 0.875rem;
                        line-height: 1.5rem;
                        font-weight: 500;
                        margin-bottom: 1.5rem;
                    }
                }
                button,
                a {
                    width: 11.125rem;
                    height: 2.625rem;
                    font-size: 0.875rem;
                }
            }
            .right {
                width: 100%;
                height: 12.5rem;
                margin-bottom: 1.5rem;
                img {
                    height: 100%;
                    object-fit: cover;
                    border-radius: 1.25rem;
                }
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .partner {
            width: 25rem;
        }
    }
}
