.container {
  display: flex;
  flex-direction: column;
  label {
    color: #16243f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  textarea {
    padding: 10px 16px;
    border-radius: 5px;
    height: 60px;
    border: 1px solid #e7e7e7;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    resize: none;
    &::placeholder {
      color: #a0a0a0;
    }
  }
}

.error {
  label {
    color: #e8505b !important;
  }
  textarea {
    border-color: #e8505b !important;
  }
}
