.container {
    background-color: #F3F3F3;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .logo {
        margin-top: 44px;
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
            width: 52px;
        }
        h1 {
                font-weight: 600;
                font-size: 20px;
                color: #111111;
        }
    }
}


@media (max-width: 900px) {
    .container {
        height: auto;
        min-height: 100vh;
    }
}