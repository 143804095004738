.container {
    margin: 3.75rem 0;
    .maxWidthWrapper {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.1875rem;
        color: #19233f;
        margin-bottom: 1.5rem;
        text-align: center;
        span {
            color: #ff713f;
            font-family: "DM Serif Display";
            font-style: italic;
        }
    }
    p {
        font-weight: 500;
        font-size: 1.25rem;
        text-align: center;
        letter-spacing: -0.022em;
        color: #666666;
        margin-bottom: 2rem;
    }
    .logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            &:first-child {
                width: 14rem;
            }
            &:nth-child(2) {
                width: 9rem;
            }
            &:nth-child(3) {
                width: 11rem;
            }
            &:nth-child(4) {
                width: 13.3125rem;
            }
            &:last-child {
                width: 11rem;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 2.5rem 0;
        .maxWidthWrapper {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin: 2.5rem 0;
        .maxWidthWrapper {
            transform: none;
            width: 90%;
        }
        h1 {
            font-size: 1.5rem;
            width: 225px;
            margin: 0 auto;
            margin-bottom: 1.5rem;
        }
        p {
            font-size: 1rem;
        }
        .logos {
            flex-wrap: wrap;
            padding-bottom: 4rem;
            justify-content: space-between;
            text-align: center;
            padding: 0;
            img {
                margin-bottom: 2.5rem;
                &:first-child {
                    width: 50%;
                }
                &:nth-child(2) {
                    width: 40%;
                }
                &:nth-child(3) {
                    width: 40%;
                }
                &:nth-child(4) {
                    width: 50%;
                }
                &:last-child {
                    width: 50%;
                    margin: 0 auto;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        h1 {
            width: auto;
        }
        .logos {
            padding-bottom: 0;
            justify-content: space-between;
            img {
                width: 6.25rem !important;
                &:last-child {
                    margin: 0;
                    margin-bottom: auto;
                }
            }
        }
    }
}
