.button {
  // dont give width: 100% pass additonalStyles instead
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
}

.primary {
  background-color: #222222;
  border: 1px solid #222222;
  color: #ffffff;
}

.primaryDisabled {
  background-color: #c2c2c2;
  color: #ffffff;
}

.secondary {
  background-color: #e6f2ff;
  color: #4384e6;
}

.outline {
  border: 1px solid #222222;
  background-color: #ffffff;
  color: #222222;
}

.big {
  font-size: 16px;
  padding: 16px;
}

.fullWidth {
  width: 100%;
}

.disabled {
  pointer-events: none;
}
