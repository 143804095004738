.container {
  padding: 76px 0;
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  .card {
    width: 564px;
    padding: 24px;
    border: 1px solid #e7e7e7;
    margin: 0 auto;
    border-radius: 8px;
    h3 {
      color: #000;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -0.84px;
      margin-bottom: 40px;
    }

    .intro {
      margin-bottom: 40px;
      p {
        &:first-child {
          color: #000;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        &:last-child {
          color: #707070;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .info {
      margin-bottom: 32px;
      .title {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 24px;
      }
      .point {
        color: #707070;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        span {
          color: #000000;
          font-weight: 500;
        }
      }
    }

    .email {
      margin-bottom: 24px;
    }
    .checkbox {
      margin: 40px 0;
    }
    .cta {
      margin: 0 auto;
    }
  }
}

@media (max-width: 900px) {
  .container {
    padding: 32px 0;

    .card {
      width: 90%;
      border: none;
      padding: 0;

      h3 {
        font-size: 24px;
      }

      .intro {
        p {
          &:first-child {
            font-size: 18px;
          }
          &:last-child {
            font-size: 14px;
          }
        }
      }
    }
  }
}
