@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/* :root {
    --root_font_size: 16px
    font-size: var(--root_font_size);
} */
:root {
    --swiper-theme-color: #FF713F;
    --swiper-pagination-color: var(--swiper-theme-color);
    --swiper-pagination-bullet-size: 12px;
    /*
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: #000;
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;
    */
  }

html {
    scroll-behavior: smooth;
    font-size: 14px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', 'Poppins', sans-serif;
    /* font-family: 'Poppins', sans-serif; */
}

button {
    cursor: pointer;
    border: none;
}

input,
textarea {
    outline: none;
}

img {
    user-select: none;
    pointer-events: none;
}

h1 {
    word-spacing: 4px;
}

p {
    word-spacing: 2px;
}

.App {
    background-color: #FFFCF4;
}

#rak_testimonials {
    padding-bottom: 80px;
}

 .swiper-pagination-bullet-active {
    background: #000000 !important;
}

@media (max-width: 700px) {
    h1 {
        word-spacing: 2px;
    }

    p {
        word-spacing: 1px;
    }
}

::selection {
    color: #FFFFFF;
    background: #000000;
}

/* @media (min-width: 900px) and (max-width: 1250px) {
    html {
        font-size: 14px;
    }
} */

@media (max-width: 900px) {
    html {
        font-size: 16px;
    }
}


@keyframes react-spinners-clip {
    0% {
        transform: rotate(0deg) !important;
    }
    50% {
        transform: rotate(180deg) !important;
    }
    100% {
        transform: rotate(360deg) !important;
    }
}