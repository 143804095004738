.container {
    background-color: #fffbf3;
    padding: 7.5rem 0;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        color: #19233f;
        font-family: "DM Serif Display";
        font-size: 2.8125rem;
        font-weight: 400;
        margin-bottom: 2.625rem;
    }
    .accordion {
        background-color: #ffffff;
        padding: 2rem 2.125rem;
        border-radius: 0.75rem;
        margin-bottom: 1rem;
        cursor: pointer;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 1.5rem;
                font-weight: 400;
            }
            svg {
                font-size: 1.5rem;
                color: #f9846b;
            }
        }
        .content {
            width: 90%;
            margin-top: 1.5rem;
            p {
                color: #6f6f6f;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.75rem;
                user-select: none;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 2.5rem 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 3.5rem 0;
        .maxWidthContainer {
            width: 90%;
            transform: none;
        }
        h1 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .accordion {
            padding: 1rem;
            .header {
                p {
                    width: 80%;
                    font-size: 1rem;
                }
            }
            .content {
                width: 100%;
                p {
                    font-size: .875rem;
                }
            }
        }
    }
}
