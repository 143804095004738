.container {
    margin: 7.5rem 0;
    position: relative;
    background-image: url("https://d1shve8gekzify.cloudfront.net/website-assets/studies.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    padding: 6.25rem 0;
    z-index: 1;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #19233fe5;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .gradient {
        position: absolute;
        clip-path: polygon(0 30%, 100% 20%, 100% 80%, 0% 95%);
        background: linear-gradient(114deg, #0149b8 40%, #4f9cff 100%);
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .maxWidthContainer {
        max-width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.8125rem;
        color: #ffffff;
        margin-bottom: 4rem;
        span {
            font-family: "DM Serif Display";
            font-style: italic;
            color: #ff713f;
        }
    }
    .mobile {
        display: none;
    }
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        margin-bottom: 1.5rem;
        .card {
            display: flex;
            flex-direction: column;
            border-radius: .75rem;
            width: 22.75rem;
            height: 21rem;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
                background: #00000066;
                .header {
                    // img {
                    //     filter: brightness(0);
                    // }
                    svg {
                        opacity: 1;
                        transform: rotate(45deg);
                    }
                }
            }
            .mainContent {
                padding: 2.5rem 2rem;
            }
            .header {
                display: flex;
                justify-content: space-between;
                svg {
                    opacity: 0;
                    width: 2rem;
                    height: 2rem;
                    transition: all 0.2s;
                    stroke-width: 1;
                    stroke: #ffffff;
                }
            }
            img {
                height: 2rem;
                margin-bottom: 1.5rem;
                transition: all 0.5s;
            }
            p {
                user-select: none;
                &:nth-child(2) {
                    font-weight: 600;
                    font-size: 1.375rem;
                    letter-spacing: -0.022em;
                    color: #ffffff;
                    margin-bottom: 1rem;
                }
                &:last-child {
                    font-weight: 500;
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                    color: #ffffff;
                }
            }
            .cardFooter {
                margin-top: auto;
                padding: 1.5rem 2rem;
                border-bottom-left-radius: .75rem;
                border-bottom-right-radius: .75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    font-size: 2.5rem;
                    font-weight: 700;
                    color: #ffffff;
                    margin: 0 .5rem;
                    transition: all 0.2s;
                }
                img {
                    height: 100%;
                    margin: 0;
                    transition: all 0.2s;
                }
                p {
                    font-weight: 500;
                    font-size: .875rem;
                    line-height: normal;
                    color: #ffffff;
                    transition: all 0.2s;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        margin-top: -2.5rem;
        padding: 0;
        .maxWidthContainer {
            width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin: 0;
        margin-bottom: 3.5rem;
        padding: 3.5rem 0;
        .gradient {
            clip-path: polygon(0 30%, 100% 20%, 100% 90%, 0% 100%);
            transform: none;
            bottom: 0;
            left: 0;
            top: 5rem;
        }
        .maxWidthContainer {
            transform: none;
            width: 100%;
        }
        .desktop {
            display: none;
        }
        h1 {
            width: 17.5rem;
            text-align: left;
            font-size: 1.5rem;
            margin-bottom: 2rem;
            padding-left: 1.25rem;
        }
        .mobile {
            display: block;
            .card {
                display: flex;
                flex-direction: column;
                background: #00000066;
                border-radius: .75rem;
                width: 18.6875rem;
                height: 18.25rem;
                cursor: pointer;
                transition: all 0.2s;
                &:first-child {
                    margin-left: 1.25rem;
                }
                .mainContent {
                    padding: 2rem 1rem;
                }
                .header {
                    display: flex;
                    justify-content: space-between;
                    svg {
                        stroke: #FFFFFF;
                        width: 2rem;
                        height: 2rem;
                        transition: all 0.2s;
                        stroke-width: 1;
                        transform: rotate(45deg);
                    }
                }
                img {
                    height: 2rem;
                    margin-bottom: 1.5rem;
                    transition: all 0.5s;
                }
                p {
                    user-select: none;
                    &:nth-child(2) {
                        font-weight: 600;
                        font-size: 1.125rem;
                        letter-spacing: -0.022em;
                        color: #FFFFFF;
                        margin-bottom: 1rem;
                    }
                    &:last-child {
                        font-weight: 500;
                        font-size: .875rem;
                        line-height: 1.5rem;
                        color: #BFBFBF;
                    }
                }
                .cardFooter {
                    margin-top: auto;
                    margin-bottom: 1.5rem;
                    padding: 0 1.5rem;
                    border-bottom-left-radius: .75rem;
                    border-bottom-right-radius: .75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        font-size: 2.5rem;
                        font-weight: 700;
                        color: #ffffff;
                        margin: 0 .5rem;
                        transition: all 0.2s;
                    }
                    img {
                        height: 100%;
                        margin: 0;
                        transition: all 0.2s;
                    }
                    p {
                        font-weight: 500;
                        font-size: .875rem;
                        line-height: normal;
                        color: #ffffff;
                        transition: all 0.2s;
                    }
                }
            }
        }
    }
}
