.container {
  width: 100%;
  label {
    display: block;
    color: #16243f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .select {
    width: 100% !important;
  }
}

.error {
  label {
    color: #e8505b !important;
  }
}
