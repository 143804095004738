.container {
    margin-top: 40px;
    width: 500px;
    min-height: 500px;
    border-radius: 14px;
    background-color: #ffffff;
    margin-bottom: auto;
    padding: 35px;
    position: relative;
    .spinner {
        position: absolute;
        z-index: 10;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .issuer {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
        color: #111111;
    }
    .date {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        color: #7c7c7c;
        margin-bottom: 24px;
    }
    .amount {
        font-family: "Inter";
        font-size: 20px;
        font-weight: 400;
        color: #111111;
        margin-bottom: 16px;
        span {
            font-weight: 600;
        }
    }
    .note {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        color: #7c7c7c;
        margin-bottom: 34px;
    }

    form {
        button {
            width: 100%;
            height: 48px;
            background-color: #111111;
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 16px;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
        }
    }
    .info {
        font-family: "Inter";
        font-size: 13px;
        font-weight: 400;
        color: #589725;
        text-align: center;
        span {
            font-weight: 600;
        }
    }
}



@media (max-width: 900px) {
    .container {
        width: 90%;
    }
}