.container {
  cursor: pointer;
  width: fit-content;
  label {
    cursor: pointer;
    color: #16243f;
    font-size: 14px;
    font-weight: 400;
    margin-left: 14px;
    user-select: none;
  }
}

// .error {
//   border: 1px solid #e8505b;
//   border-radius: 3px;
// }
