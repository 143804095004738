.container {
  display: flex;
  cursor: pointer;
  .radio {
    width: 18px;
    height: 18px;
    border: 2px solid #e7e7e7;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 0;
      height: 0;
      border-radius: 100%;
      background-color: #6bb033;
      transition: all 0.2s;
    }
  }
  label {
    margin-left: 8px;
  }
}

.checked {
  .radio {
    border-color: #6bb033;
    span {
      width: 10px;
      height: 10px;
    }
  }
}
