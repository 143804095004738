.container {
    background: linear-gradient(79deg, #fff2d0 70%, rgba(255, 242, 208, 0) 100%);
    padding: 3.9375rem 0;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        color: #19233f;
        font-family: "DM Serif Display";
        font-size: 2.8125rem;
        font-weight: 400;
        margin-bottom: 5rem;
        span {
            color: #ff713f;
            font-family: "DM Serif Display";
            font-style: italic;
        }
    }
    .testimonialSlide {
        p {
            color: #6e6e6e;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2.5rem;
            margin-bottom: 5rem;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 3.625rem;
                margin-right: 1.5rem;
            }
            p {
                color: #000;
                text-align: center;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.4375rem;
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 2rem 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 3.5rem 0;
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        h1 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .testimonialSlide {
            p {
                font-size: .875rem;
                line-height: 1.5rem;
                margin-bottom: 1.5rem;
            }
            div {
                img {
                    width: 3rem;
                    margin-right: 1rem;
                }
                p {
                    font-size: 1rem;
                }
            }
        }
    }
}