@import "../../assets/colors.scss";
.wrapper {
    padding-bottom: 6.25rem;
    .logo {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        margin-bottom: 112px;
        img {
            margin-right: 16px;
            width: 2rem;
        }
        p {
            font-weight: 600;
            font-size: 24px;
        }
    }
    .heading {
        font-size: 2.25rem;
        color: $black-text;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    .paragraph {
        font-size: 1.125rem;
        color: $grey;
        margin-bottom: 1rem;
        span {
            font-weight: 600;
        }
        a {
            text-decoration: none;
            color: $primary;
        }
    }
    .title {
        font-size: 1.25rem;
        color: $black-text;
        font-weight: 500;
        margin-bottom: .5rem;
        margin-top: 2rem;
    }
}

.maxWidthWrapper {
    width: 56.25rem;
    margin: 0 auto;
}

@media (max-width: 56.25rem) {
    .maxWidthWrapper {
        width: 90%;
    }
}