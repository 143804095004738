.container {
    height: 100vh;
    position: relative;
    .background {
        height: 100%;
        // margin-top: -2rem;
    }
    .maxWidthContainer {
        width: 71.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .heroText {
            border-radius: 1.25rem;
            width: fit-content;
            h1 {
                font-family: "DM Serif Display";
                font-weight: 400;
                font-size: 2.9375rem;
                letter-spacing: -0.02em;
                color: #ffffff;
                width: 37.5rem;
                margin-bottom: 1.5rem;
                span {
                    font-family: "DM Serif Display";
                    color: #ff713f;
                    font-style: italic;
                }
            }
            p {
                font-weight: 400;
                font-size: 1rem;
                color: #ffffff;
                width: 31.25rem;
                line-height: 1.8rem;
                margin-bottom: 3.5rem;
                span {
                    font-weight: 600;
                }
            }
            a {
                background-color: #ff713f;
                padding: 0.75rem 1.5rem;
                border-radius: 0.5rem;
                text-decoration: none;
                color: #ffffff;
                font-weight: 500;
                font-size: 1rem;
                display: flex;
                align-items: center;
                width: fit-content;
                svg {
                    margin-left: 0.5rem;
                }
            }
            .phone {
                margin-top: 1rem;
                a {
                    background-color: transparent;
                    padding: 0;
                    border-radius: 0;
                    font-weight: 400;
                    font-size: 1rem;
                    color: #c0c0c0 !important;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        .maxWidthContainer {
            transform: translate(-50%, -50%) scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-top: 0;
        height: 100vh;
        .background {
            div {
                background-position: top !important;
            }
        }
        .maxWidthContainer {
            width: 100%;
            height: 100%;
            transform: none;
            top: 0;
            left: 0;
            background-color: #19233fde;
            display: flex;
            align-items: center;
            justify-content: center;
            .heroText {
                background-color: transparent;
                width: 90%;
                margin: 0 auto;
                transform: none;
                padding: 0;
                border-radius: 0;
                margin-top: 8rem;
                h1,
                p {
                    width: 100%;
                    transform: none;
                }
                h1 {
                    font-size: 1.875rem;
                    width: 18.25rem;
                    margin-bottom: 1rem;
                }
                p {
                    margin-bottom: 2rem;
                }
                a {
                    transform: none;
                }
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .maxWidthContainer {
            .heroText {
                p {
                    width: 25rem;
                }
            }
        }
    }
}
