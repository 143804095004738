.container {
    margin-top: 40px;
    width: 500px;
    border-radius: 14px;
    background-color: #ffffff;
    margin-bottom: auto;
    padding: 35px;
    position: relative;
    text-align: center;
    .tick {
        margin-bottom: 32px;
        svg {
            font-size: 100px;
            color: #c8573e;
            margin-bottom: 18px;
        }
        p {
            color: #c8573e;
            font-family: "Inter";
            font-size: 20px;
            font-weight: 600;
        }
    }
    .amount {
        color: #111;
        font-family: "Inter";
        font-size: 20px;
        font-weight: 400;
        margin: 16px 0;
        span {
            font-weight: 700;
        }
    }
    .message {
        color: #7c7c7c;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
    }
    .paymentMethod,
    .issuer {
        color: #7c7c7c;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }
    .buttons {
        display: flex;
        align-items: center;
        margin-top: 52px;
        button {
            &:first-child {
                border-radius: 8px;
                background: #FFFFFF;
                border: 1px solid #8D8D8D;
                width: 100%;
                height: 48px;
                color: #8D8D8D;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.176px;
                margin-right: 10px;
            }
            &:last-child {
                border-radius: 8px;
                background: #111;
                width: 100%;
                height: 48px;
                color: #ffffff;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.176px;
            }
        }
    }
}


@media (max-width: 900px) {
    .container {
        width: 90%;
    }
}