.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    text-align: center;
    .heading {
        margin-top: 40px;
        margin-bottom: 22px;
        font-family: "Inter";
        font-size: 20px;
        color: #111111;
        font-weight: 400;
    }
    .description {
        color: #7c7c7c;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
    }
}
