.wrapper {
    margin-bottom: 7.5rem;
    padding: 1.25rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .maxWidthContainer {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .links {
            display: flex;
            margin-left: auto;
            a {
                text-decoration: none;
                margin-right: 2.5rem;
                font-weight: 500;
                font-size: 1rem;
                letter-spacing: -0.022em;
                color: #ffffff;
                transition: all 0.2s;
                &:hover {
                    // color: #000000;
                }
                &:last-child {
                    margin-right: 3.75rem;
                }
            }
        }
        h1 {
            font-weight: 600;
            font-size: 1.875rem;
            line-height: 1.5rem;
            cursor: pointer;
            color: #ffffff;
        }
        a {
            text-decoration: none;
        }
        button {
            background-color: #111111;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.75rem 1rem;
            color: #ffffff;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5rem;
            letter-spacing: -0.011em;
            border-radius: 0.5rem;
            transition: all 0.2s;
            &:hover {
                background-color: #505050;
            }
            &:active {
                background-color: #000000;
            }
        }
        .mobileButton {
            display: none;
        }
    }
}

.scrolling {
    background-color: #ffffff;
}

.maxWidthContainer {
    width: 71.25rem;
    margin: 0 auto;
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .maxWidthContainer {
        transform: scale(0.8);
    }
    .wrapper {
        margin-bottom: 3.125rem;
    }
}

@media (max-width: 62.5rem) {
    .wrapper {
        padding: 0;
        margin: 0;
        .maxWidthContainer {
            width: 90%;
            height: 3.75rem;
            transform: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1 {
                font-size: 1.125rem;
            }
            a {
                display: none;
            }
            .mobileButton {
                background-color: #111111;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .5rem 1rem;
                color: #ffffff;
                font-size: .875rem;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: -0.011em;
                border-radius: 0.5rem;
                transition: all 0.2s;
                &:hover {
                    background-color: #505050;
                }
                &:active {
                    background-color: #000000;
                }
                &:hover {
                    background-color: #505050;
                }
            }
        }
    }
}
