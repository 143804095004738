.container {
    background: url("../../../assets/raklanding/chronicconditions/background.svg") #fffbf3;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7.5rem 0;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        color: #19233f;
        font-family: "DM Serif Display";
        font-size: 2.8125rem;
        font-weight: 400;
        margin-bottom: 5rem;
        span {
            color: #ff713f;
            font-family: "DM Serif Display";
            font-style: italic;
        }
    }
    .cards {
        display: flex;
        justify-content: space-between;
        .column {
            &:nth-child(2) {
                margin-top: 6.25rem;
            }
            .card {
                width: 21.8125rem;
                height: 21.375rem;
                border-radius: .75rem;
                background: #fff;
                box-shadow: .25rem .6875rem 2.9375rem 0rem rgba(206, 126, 52, 0.2);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                text-align: center;
                &:first-child {
                    margin-bottom: 2.75rem;
                }
                img {
                    margin-bottom: 2rem;
                }
                p {
                    &:nth-child(2) {
                        color: #ee907b;
                        font-size: 1.5rem;
                        font-weight: 600;
                        margin-bottom: 1.5rem;
                    }
                    &:nth-child(3) {
                        color: #6f6f6f;
                        font-size: 1rem;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}


@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        padding: 2.5rem 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        padding: 3.5rem 0;
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        h1 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .cards {
            flex-direction: column;
            .column {
                &:nth-child(2) {
                    margin-top: 0;
                }
                .card {
                    width: 100%;
                    margin-bottom: 1rem;
                    height: auto;
                    aspect-ratio: 4/3;
                    &:first-child {
                        margin-bottom: 1rem;
                    }
                    img {
                        margin-bottom: 1.5rem;
                    }
                    p {
                        &:nth-child(2) {
                            font-size: 1rem;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(3) {
                            font-size: .875rem;
                        }
                    }
                }
            }
        }
    }
}