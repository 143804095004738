.container {
    margin-bottom: 6.25rem;
    .maxWidthWrapper {
        width: 71.25rem;
        margin: 0 auto;
    }
    .header {
        h1 {
            font-family: "DM Serif Display";
            font-weight: 400;
            font-size: 2.8125rem;
            color: #000000;
            margin-bottom: 0.5rem;
            span {
                font-family: "DM Serif Display";
                color: #ff713f;
                font-style: italic;
            }
        }
        p {
            font-weight: 400;
            font-size: 1rem;
            color: #666666;
            margin-bottom: 3.5rem;
        }
    }
    .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .stat {
            p {
                &:first-child {
                    font-family: "DM Serif Display";
                    font-weight: 400;
                    font-size: 2.5rem;
                    letter-spacing: -0.022em;
                    color: #19233f;
                    margin-bottom: 1rem;
                }
                &:last-child {
                    width: 18.625rem;
                    font-weight: 500;
                    font-size: 1.25rem;
                    line-height: 2.25rem;
                    letter-spacing: -0.022em;
                    color: #666666;
                }
            }
        }
        span {
            height: 8.75rem;
            width: 0.3125rem;
            background-color: #f2ebdd;
            border-radius: 0.625rem;
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin-bottom: 0;
        .maxWidthWrapper {
            // width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthWrapper {
            transform: none;
            width: 90%;
        }
        .header {
            h1 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
            p {
                font-size: 0.875rem;
                line-height: 1.5rem;
                margin-bottom: 2.5rem;
            }
        }
        .stats {
            flex-direction: column;
            .stat {
                text-align: center;
                margin-bottom: 4.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
                p {
                    &:first-child {
                        font-size: 1.6875rem;
                        margin-bottom: 0.3125rem;
                    }
                    &:last-child {
                        width: 12.75rem;
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                }
            }
            span {
                display: none;
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .stats {
            flex-direction: row;
            .stat {
                margin-bottom: 0 !important;
            }
        }
    }
}
