.container {
    margin-bottom: 6.25rem;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
    }
    h1 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.8125rem;
        color: #000000;
        margin-bottom: 3.5rem;
        span {
            font-family: "DM Serif Display";
            font-style: italic;
            color: #ff713f;
        }
    }
    .cards {
        display: flex;
        gap: 2.625rem;
        div {
            width: 22.1875rem;
            display: flex;
            flex-direction: column;
            border-bottom: 0.0625rem solid #d9d9d9;
            img {
                width: 100%;
                margin-bottom: 1rem;
            }
            p {
                font-weight: 400;
                font-size: 1.25rem;
                letter-spacing: -0.022em;
                color: #000000;
                margin-bottom: 6.25rem;
            }
            button {
                width: fit-content;
                margin-top: auto;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 1rem;
                letter-spacing: -0.022em;
                color: #ff713f;
                background-color: transparent;
                margin-bottom: 1rem;
                svg {
                    font-size: 1.25rem;
                    margin-left: 1rem;
                }
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin: 0;
        .maxWidthContainer {
            // width: 100%;
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            transform: none;
            width: 90%;
        }
        h1 {
            font-size: 1.5rem;
            margin-bottom: 2.5rem;
        }
        .cards {
            flex-direction: column;
            div {
                width: 100%;
                img {
                    margin-bottom: 1.5rem;
                }
                p {
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                }
                button {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .cards {
            div {
                width: 25rem;
            }
        }
    }
}
