.container {
    margin-bottom: 7.5rem;
    .maxWidthContainer {
        width: 71.25rem;
        margin: 0 auto;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
        border-radius: 1.25rem;
        padding: 3rem 4.625rem;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
    .background {
        position: absolute;
        width: 8.75rem;
        top: 0;
        right: 18rem;
        height: 100%;
        background: #fff7e8;
        transform: skew(-10deg);
        z-index: -1;
    }
    h1 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 2.8125rem;
        color: #000000;
        margin-bottom: 3.75rem;
        span {
            font-family: "DM Serif Display";
            font-style: italic;
            color: #ff713f;
        }
    }
    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;
        color: #19233f;
        margin-bottom: 3.5rem;
    }
    .logos {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            &:first-child {
                width: 10rem;
            }
            &:nth-child(2) {
                width: 8rem;
            }
            &:nth-child(3) {
                width: 9rem;
            }
            &:nth-child(4) {
                width: 8rem;
            }
            &:nth-child(5) {
                width: 12rem;
            }
        }
    }
}

@media (min-width: 62.5rem) and (max-width: 75rem) {
    .container {
        margin-bottom: 0;
        .maxWidthContainer {
            transform: scale(0.8);
        }
    }
}

@media (max-width: 62.5rem) {
    .container {
        margin-bottom: 3.5rem;
        .maxWidthContainer {
            width: 90%;
            transform: none;
            padding: 2rem 1.5rem;
        }
        .background {
            width: 6rem;
            right: 8rem;
        }
        h1 {
            font-size: 1.5rem;
            margin-bottom: 2rem;
            text-align: left;
        }
        p {
            font-size: 0.875rem;
            margin-bottom: 2rem;
        }
        .logos {
            flex-direction: column;
            img {
                margin-bottom: 2rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &:nth-child(2) {
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: 48rem) and (max-width: 62.5rem) {
    .container {
        .logos {
            flex-direction: row;
            img {
                width: 6.25rem !important;
                margin-bottom: 0;
            }
        }
    }
}
