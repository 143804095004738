.container {
    background-color: #f3f3f3;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .spinner {
        position: absolute;
        z-index: 10;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo {
        margin-top: 44px;
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
            width: 52px;
        }
        h1 {
            font-weight: 600;
            font-size: 20px;
            color: #111111;
        }
    }
}


@media (max-width: 900px) {
    .container {
        height: auto;
        min-height: 100vh;
    }
}